@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  html {
    font-size: 16px;
  }
  html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    @apply bg-slate-100;
  }
  
  .toast-wrapper {
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }

  [data-loading="true"] input {
    @apply animate-pulse;
  }

  [data-loading="true"] .input-primary:required:invalid {
    @apply animate-pulse !border-0;
  }

  .button-primary {
    @apply px-4 py-2 rounded-lg bg-slate-900 text-yellow-200 font-medium text-lg hover:rounded hover:-translate-y-0.5 hover:translate-x-0.5 transition-all;
  }
  
  .button-primary:disabled {
    @apply bg-slate-300 text-slate-900;
  }

  .input-primary {
    @apply px-2 py-1 rounded-lg bg-yellow-200 text-slate-900 font-medium outline-none;
  }

  .input-primary:required:invalid {
    @apply border-2 border-red-500;
  }

  .main-grid-auto {
    @apply grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5;
  }
}
